import React from "react";

const RightArrowIcon = () => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.22754 4.0013C9.22754 3.88585 9.18197 3.78407 9.09082 3.69596L6.06934 0.683594C6.02073 0.634983 5.97059 0.600043 5.91895 0.578776C5.87033 0.557509 5.81869 0.546875 5.764 0.546875C5.65158 0.546875 5.5574 0.584852 5.48145 0.660807C5.40549 0.733724 5.36751 0.826389 5.36751 0.938802C5.36751 0.99349 5.37663 1.04666 5.39486 1.09831C5.41309 1.14692 5.44043 1.18945 5.47689 1.22591L6.49772 2.26497L8.30697 3.91471L8.39811 3.68685L6.93066 3.5957H1.16569C1.0472 3.5957 0.949978 3.63368 0.874023 3.70964C0.801107 3.78559 0.764648 3.88281 0.764648 4.0013C0.764648 4.11979 0.801107 4.21701 0.874023 4.29297C0.949978 4.36892 1.0472 4.4069 1.16569 4.4069H6.93066L8.39811 4.31576L8.30697 4.09245L6.49772 5.73763L5.47689 6.77669C5.44043 6.81315 5.41309 6.85721 5.39486 6.90885C5.37663 6.95747 5.36751 7.00912 5.36751 7.0638C5.36751 7.17622 5.40549 7.26888 5.48145 7.3418C5.5574 7.41775 5.65158 7.45573 5.764 7.45573C5.87337 7.45573 5.97211 7.4132 6.06022 7.32813L9.09082 4.30664C9.18197 4.21853 9.22754 4.11675 9.22754 4.0013Z"
        fill="#45AAF2"
      />
    </svg>
  );
};

export default RightArrowIcon;
