import styled from "styled-components";

export const MessageContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.75;
  color: ${({ theme }) => theme.colors.nero};
  padding: 8px 16px;
  border-radius: 20px;
  margin: 0;
  img {
    object-fit: contain;
    display: block;
    max-width: 100%;
    border-radius: 8px;
  }
`;

export const WaitingBotWrapper = styled.div`
  width: 76px;
  height: 44px;
  background: #efefef;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 76px;
    height: 40px;
  }
`;

export const MovingDots = styled.img`
  width: 100%;
`;

export const ChatWrappers = styled.div`
  width: 555px;
  margin: 0 auto;
  @media (max-width: 640px) {
    width: 100%;
  }
`;

export const MessageWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: flex-end;
  &.user {
    ${MessageContent} {
      background-color: #daeefc;
      margin-left: 4px;
      max-width: 100%;
      overflow-wrap: anywhere;
    }
    flex-direction: row-reverse;
  }
  &.server {
    ${MessageContent} {
      background-color: #efefef;
      margin-right: 4px;
      max-width: 100%;
      overflow-wrap: anywhere;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &.other {
    ${MessageContent} {
      background-color: #efefef;
    }
  }
`;
export const MessageTime = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 2;
  color: rgba(0, 0, 0, 0.38);
  white-space: nowrap;
`;
