import Navbar from "@Components/Navbar";
import BaimsMessage from "@Components/BaimsMessage";
import React, { Fragment, useContext, useState } from "react";
import { LayoutWrapper } from "./Layout.styled";

type Props = {
  children?: React.PropsWithChildren<any>;
};

export default function Layout({ children }: Props) {
  return (
    <Fragment>
      <Navbar />
      <BaimsMessage />
      <LayoutWrapper>{children}</LayoutWrapper>
    </Fragment>
  );
}
