import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavbarSearchWrapper, SearchBoxWrapper } from "../Navbar.styled";

export default React.memo(function NavbarSearch(): ReactElement {
  const { t } = useTranslation("common");
  const [searchValue, setSearchValue] = useState("");

  const enterClickHandler = (e) => {
    if (e.key === "Enter" && searchValue.trim() !== "") {
      window.location.href = `${process.env.REACT_APP_BAIMS_URL}/search?query=${searchValue}`;
    }
  };

  return (
    <NavbarSearchWrapper>
      <SearchBoxWrapper>
        <div className="ais-SearchBox search-box">
          <input
            type="search"
            placeholder={t("Search_box_placeholder")}
            className="ais-SearchBox-input search-box"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => enterClickHandler(e)}
          />
          <button
            onClick={() => {
              if (searchValue.trim() !== "") {
                window.location.href = `${process.env.REACT_APP_BAIMS_URL}/search?query=${searchValue}`;
              }
            }}
            type="submit"
            title="Submit your search query."
            className="ais-SearchBox-submit"
          >
            <img src="/images/icons/magnifier-black.svg" alt="" />
          </button>
        </div>
      </SearchBoxWrapper>
    </NavbarSearchWrapper>
  );
});
