import { SendButton } from "@Components/InputBar/InputBar.styled";
import styled from "styled-components";

export const ChatContainer = styled.div``;

export const BaimsMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  top: 108px;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0px;
  max-width: 1140px;
  width: 100%;
  padding: 12px 16px;
  margin: 0px auto;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
  @media (max-width: 1024px) {
    top: 84px;
  }
  @media (max-width: 576px) {
    top: 72px;
  }
`;

export const AlertMessage = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontSizes.body12};
  line-height: 20px;
  color: #262626;
`;

export const ReportButtonWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: 7px;
  text-decoration: none;
  outline: none;
`;

export const ReportMessage = styled.p`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  font-size: ${({ theme }) => theme.fontSizes.body12};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.seagule};
`;

export const ArrowWapper = styled.div`
  &:lang(ar) {
    transform: rotate(180deg);
  }
`;

export const PrestoButton = styled(SendButton)<{ readonly isActive?: boolean }>`
  padding: 10px 12px;
  width: auto;
  height: 44px;
  border-radius: 10px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.gray8 : theme.colors.seagule)};
`;
