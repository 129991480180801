import { ChatContainer } from "@Components/BaimsMessage/BaimsMessage.styled";
import EmptyState from "@Components/EmptyState";
import LoadingIcon from "@Components/EmptyState/LoadingIcon";
import ImageCropper from "@Components/ImageCropper";
import { BgBlur } from "@Components/ImageCropper/ImageCropper.styled";
import InputBar from "@Components/InputBar";
import Message from "@Components/Message";
import {
  ChatWrappers,
  MessageContent,
  MessageWrapper,
  MovingDots,
  WaitingBotWrapper,
} from "@Components/Message/Message.styled";
import { AuthDatasContext } from "context/Auth.context";
import Cookies from "js-cookie";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";

interface MessageType {
  id: string;
  type: "text" | "image";
  content: string;
  timestamp: number;
  sender: "user" | "server" | "other";
}

const uploadImageToS3 = async (imageData: string): Promise<string> => {
  const response = await fetch(`${process.env.REACT_APP_URL_API_CHAT}api/upload`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("BAIMS_TOKEN")}`,
    },
    body: JSON.stringify({ imageData }),
  });

  if (!response.ok) {
    throw new Error("Failed to upload image");
  }

  const data = await response.json();
  return data.imageUrl;
};

const isLatexContent = (content: string): boolean => {
  const latexPattern = /\$([^$]*)\$/;
  return latexPattern.test(content);
};

const ChatBox: React.FC = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);
  const [isWaitingBot, setIsWaitingBot] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const { imageLoaded, setImageLoaded } = useContext(AuthDatasContext);
  const scrollBottomRef = useRef<HTMLDivElement>();

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollBottomRef.current) scrollBottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1);
  };
  const handleMessageSend = (content: string) => {
    if (content.trim()) {
      const newMessage: MessageType = {
        id: Date.now().toString(),
        type: "text",
        content: content.trim(),
        timestamp: Date.now(),
        sender: "user", // Add the sender property
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsWaitingBot(true);
      socketRef.current?.emit("send_message", newMessage);
    }
  };

  const handleImageSend = async (imageBlob: Blob | null) => {
    setIsLoading(true);
    try {
      if (!imageBlob) {
        return;
      }

      const imageData = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });

      const imageUrl = await uploadImageToS3(imageData);

      const newMessage: MessageType = {
        id: Date.now().toString(),
        type: "image",
        content: imageUrl,
        timestamp: Date.now(),
        sender: "user",
      };

      socketRef.current?.emit("send_message", newMessage);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsWaitingBot(true);
      // Set imageToCrop back to null to hide the ImageCropper component
      setImageToCrop(null);
      setImageLoaded(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };

  const handleImageSelect = (imageFile: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImageToCrop(reader.result as string);
    };
    reader.readAsDataURL(imageFile);
  };

  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_URL_API_CHAT, {
      auth: {
        token: `Bearer ${Cookies.get("BAIMS_TOKEN")}`,
      },
    });

    socketRef.current.on("receive_message", (message: MessageType) => {
      if (message.sender === undefined) {
        message.sender = "server";
      }
      setIsWaitingBot(false);
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socketRef.current.on("connect_error", (err) => {
      console.log("Connection Error:", err.message);
    });

    socketRef.current.on("connect_timeout", () => {
      console.log("Connection Timeout");
    });

    return () => {
      socketRef.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <ChatContainer>
      {imageLoaded && <BgBlur />}
      <div className="chat-container">
        <div className="chat-area">
          {messages?.length ? (
            <ChatWrappers>
              {messages.map((message) => (
                <Message
                  key={message.id}
                  message={message}
                  isSelf={message.sender === "user"}
                  isLatex={isLatexContent(message.content)}
                />
              ))}
              {isWaitingBot && (
                <WaitingBotWrapper className="server">
                  <MessageContent>
                    <MovingDots src="/images/gifs/animated-dots.gif" alt="loading_gif" />
                  </MessageContent>
                </WaitingBotWrapper>
              )}
              <div ref={scrollBottomRef} />
            </ChatWrappers>
          ) : (
            <EmptyState />
          )}
        </div>
        {imageToCrop ? (
          <>
            <ImageCropper
              showCropper={showCropper}
              setImageToCrop={setImageToCrop}
              setShowCropper={setShowCropper}
              isLoading={isLoading}
              src={imageToCrop}
              onCrop={handleImageSend}
            />
            <div>
              <InputBar
                showCropper={showCropper}
                setImageToCrop={setImageToCrop}
                setShowCropper={setShowCropper}
                onSendMessage={handleMessageSend}
                onImageSelect={handleImageSelect}
              />
            </div>
          </>
        ) : (
          <div>
            <InputBar
              showCropper={showCropper}
              setImageToCrop={setImageToCrop}
              setShowCropper={setShowCropper}
              onSendMessage={handleMessageSend}
              onImageSelect={handleImageSelect}
            />
          </div>
        )}
      </div>
    </ChatContainer>
  );
};

export default ChatBox;
