import { GoBack, Title, Wrapper } from "./NotFound.styled";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t: tCommon } = useTranslation("common");
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="#DBE1EC" viewBox="0 0 48 48">
        <path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"></path>
      </svg>
      <Title>{tCommon("not_found")}</Title>
      <GoBack href={"/"}>{tCommon("back_to_home")}</GoBack>
    </Wrapper>
  );
}
