/* eslint-disable */
import { APPLE_APPSTORE_LINK, GOOGLE_PLAY_LINK } from "constants/AppStoreLinks";
import { useTranslation } from "react-i18next";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useClickAway, useCookie, useMedia } from "react-use";
import { Col, Container, Row } from "styled-bootstrap-grid";
import NavbarLangSwitch from "./components/NavbarLangSwitch";
import NavbarSearch from "./components/NavbarSearch";
import ProfileButton from "./components/ProfileButton";
import {
  AppsWrapper,
  BlurredBg,
  BurgerMenu,
  DownloadAppLink,
  Header,
  HeaderInner,
  LogoLink,
  NavLi,
  NavLink,
  NavUl,
  ProfileBtn,
  ResMenuContainer,
  ResMenuIcon,
  ResMenuLi,
  ResMenuLink,
  ResMenuProfile,
  ResMenuTitle,
  ResMenuUl,
  ResMenuWrapper,
  Separator,
  StyledLogo,
} from "./Navbar.styled";
import { LOCALE_COOKIE_NAME } from "@Configs/constants";
import { AuthDatasContext } from "context/Auth.context";
import Link from "@Components/common/Link";

interface Props {}

declare global {
  interface Window {
    fullpage_api: any;
  }
}

export default function Navbar({}: Props) {
  const { t } = useTranslation("common");
  const [locale, updateCookieLang] = useCookie(LOCALE_COOKIE_NAME);
  const { userData } = useContext(AuthDatasContext);
  const [showBlurredBg, setShowBlurredBg] = useState(false);
  const [resMenu, setResMenu] = useState(false);
  const [imgSrc, setImgSrc] = useState<null | string>(null);
  const [logoNavigate, setLogoNavigate] = useState(null);
  const isDesktop = useMedia("(min-width: 992px)");
  const isMobile = useMedia("(max-width: 576px)");
  const resMenuRef = useRef();

  useClickAway(resMenuRef, () => {
    setResMenu(false);
  });

  useEffect(() => {
    setShowBlurredBg(resMenu);
    if (window && window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(!resMenu);
    }
  }, [resMenu]);

  useEffect(() => {
    if (userData) {
      setImgSrc(userData.profile_picture.url);
    }
  }, []);

  return (
    <Fragment>
      <BlurredBg isActive={showBlurredBg} />
      <Header navBorder={true} variant={"white-bg"} isResMenuOpen={resMenu} className={""}>
        <Container>
          <Row>
            <Col col={12}>
              <HeaderInner>
                <LogoLink href={logoNavigate}>
                  <HeaderInner>
                    <StyledLogo src={`/images/logos/logo-${locale}.svg`} alt={t("baims")} />
                  </HeaderInner>
                </LogoLink>
                <NavUl>
                  <NavLi>
                    <NavLink as="div">
                      <Link href={process.env.REACT_APP_BAIMS_URL}>{t("back_to_baims")}</Link>
                    </NavLink>
                  </NavLi>
                </NavUl>
                {!isMobile && <NavbarSearch />}
                <NavbarLangSwitch setShowBlurredBg={setShowBlurredBg} />
                <Separator />
                {isDesktop ? (
                  <ProfileButton />
                ) : (
                  <ResMenuContainer ref={resMenuRef}>
                    <BurgerMenu onClick={() => setResMenu((prev) => !prev)} className={resMenu && "cross"} />
                    <ResMenuWrapper isOpen={resMenu}>
                      {isMobile && <NavbarSearch />}
                      {isMobile && (
                        <div>
                          <ResMenuTitle>{t("the-language")}</ResMenuTitle>
                          <NavbarLangSwitch setShowBlurredBg={setShowBlurredBg} />
                        </div>
                      )}
                      <ResMenuUl>
                        {/* NOTE: THIS IS COMMENT UNTILL WE CREATE THIS PAGES */}
                        {Boolean(userData) && (
                          <ResMenuLi className="no-border">
                            {/* REMOVE HREF UNTILL WE LINK THIS */}
                            <ResMenuLink>
                              <ResMenuProfile>
                                <ProfileBtn type="button" className="gray">
                                  {imgSrc === null ? (
                                    <div>{userData.name === null ? "B" : userData.name[0]}</div>
                                  ) : (
                                    <img src={imgSrc} alt={userData.name} onError={() => setImgSrc(null)} />
                                  )}
                                </ProfileBtn>
                                <span>{userData?.name}</span>
                              </ResMenuProfile>
                            </ResMenuLink>
                          </ResMenuLi>
                        )}
                        <ResMenuLi>
                          <ResMenuLink href={process.env.REACT_APP_BAIMS_URL}>Get back to Baims</ResMenuLink>
                        </ResMenuLi>
                      </ResMenuUl>
                      <ResMenuTitle>{t("get-the-app")}</ResMenuTitle>
                      <AppsWrapper>
                        <DownloadAppLink href={APPLE_APPSTORE_LINK} target="_blank" rel="noreferrer noopener">
                          <img src="/images/platform/app_apple.svg" alt="Apple store" />
                        </DownloadAppLink>
                        <DownloadAppLink href={GOOGLE_PLAY_LINK} target="_blank" rel="noreferrer noopener">
                          <img src="/images/platform/app_google.svg" alt="Google store" />
                        </DownloadAppLink>
                      </AppsWrapper>
                    </ResMenuWrapper>
                  </ResMenuContainer>
                )}
              </HeaderInner>
            </Col>
          </Row>
        </Container>
      </Header>
    </Fragment>
  );
}
