import { css } from "styled-components";

const breakpoints = {
  414: 413.98,
  375: 374.98,
  576: 575.98,
  768: 767.98,
  895: 894.98,
  992: 991.98,
  1024: 1023.99,
  1200: 1199.98,
  1920: 1920.1,
};

export const mq = (key: keyof typeof breakpoints) => {
  return `@media (max-width: ${breakpoints[key]}px)`;
};

export const mw = (key: keyof typeof breakpoints) => {
  return `@media (min-width: ${breakpoints[key]}px)`;
};

const hexToRgb = (hex) => {
  // http://stackoverflow.com/a/5624139
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgba = (hex, alpha) => {
  const color = hexToRgb(hex);
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

export const stripButton = css`
  appearance: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
`;
