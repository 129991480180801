import RightArrowIcon from "images/icons/RightArrowIcon";
import { useTranslation } from "react-i18next";
import {
  AlertMessage,
  ArrowWapper,
  BaimsMessageWrapper,
  ReportButtonWrapper,
  ReportMessage,
} from "./BaimsMessage.styled";

const BaimsMessage = () => {
  const { t } = useTranslation("common");
  return (
    <BaimsMessageWrapper>
      <AlertMessage>{t("baims_alert_message")}</AlertMessage>
      <ReportButtonWrapper href="https://airtable.com/shrp6wiefSAuMASp7">
        <ReportMessage>{t("reprot_bug")}</ReportMessage>
        <ArrowWapper>
          <RightArrowIcon />
        </ArrowWapper>
      </ReportButtonWrapper>
    </BaimsMessageWrapper>
  );
};

export default BaimsMessage;
