import { MathJax, MathJaxContext } from "better-react-mathjax";
import { AuthDatasContext } from "context/Auth.context";
import moment from "moment";
import React, { useContext } from "react";
import "./Message.css"; // Import the CSS file
import { MessageContent, MessageTime, MessageWrapper } from "./Message.styled";

interface MessageProps {
  message: {
    id: string;
    type: "text" | "image";
    content: string;
    timestamp: number;
    sender: "user" | "server" | "other";
  };
  isSelf: boolean;
  isLatex?: boolean;
}

const isLatexContent = (content: string): boolean => {
  const regex = /(\$.*?\$)|(\$\$.*?\$\$)|(\\begin{.*?}\n(.*\n)*\\end{.*?})/;
  return regex.test(content);
};

const Message: React.FC<MessageProps> = ({ message, isLatex = false }) => {
  const { userData } = useContext(AuthDatasContext);

  const displayContent = () => {
    if (isLatex) {
      const wrapped = /\\begin\{[a-zA-Z]+\}.*\\end\{[a-zA-Z]+\}/.test(message.content);
      const wrappedContent = wrapped
        ? message.content
        : `\\begin{aligned}${message.content.replace(/\n/g, "\\\\").replace(/ /g, "\\ ")}\\end{aligned}`;

      //return <MathComponent tex={wrappedContent} />;
      return (
        <MathJaxContext>
          <MathJax>{wrappedContent}</MathJax>
        </MathJaxContext>
      );
    } else {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: message.content.replace(/\n/g, "<br>"),
          }}
        />
      );
    }
  };

  const { type, content, timestamp, sender } = message;

  return (
    <MessageWrapper className={`${sender}`}>
      {type === "text" && <MessageContent dir="auto">{displayContent()}</MessageContent>}
      {type !== "text" && (
        <MessageContent dir="auto">
          <img src={content} alt="User uploaded" />
        </MessageContent>
      )}
      <MessageTime>
        {sender === "user" ? moment(timestamp).format("hh:mm A") : moment.unix(timestamp).format("hh:mm A")}
      </MessageTime>
    </MessageWrapper>
  );
};

export default Message;
