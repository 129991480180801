export default function EmptyIcon() {
  return (
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M146.001 42L75.2266 59.9847L88.1914 67.3758L146.001 42Z" fill="#DFE2E5" />
      <path d="M146.001 42L88.1917 67.3758L85.6914 82.8388L146.001 42Z" fill="#C6CACC" />
      <path d="M93.3092 73.6953L85.6914 82.8382L96.8591 79.6599L93.3092 73.6953Z" fill="#AEAEAF" />
      <path d="M93.3631 73.6289L92.3779 80.9553L96.8466 79.648L93.3631 73.6289Z" fill="#9B9B9B" />
      <path d="M103.441 91.1755L93.3975 73.6071L146 42L103.441 91.1755Z" fill="#DFE3E5" />
      <path
        d="M78.9555 89.1367C70.1571 98.4311 58.0061 104.63 45.0856 104.258C43.0628 104.2 40.9775 103.832 39.2992 102.696C37.621 101.561 36.4403 99.5222 36.7707 97.5179C37.0206 96.0045 38.1508 94.6689 39.6014 94.1779C42.545 93.1798 46.5886 96.0166 48.2709 98.316C49.8928 100.532 50.2816 103.278 49.6873 105.927C48.428 111.542 43.2381 116.456 37.5968 117.438C28.3632 119.042 18.5413 115.303 11.1875 109.873"
        stroke="#023447"
        strokeWidth="1.88"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
