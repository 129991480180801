//@ts-nocheck
//@ts-ignore
/* eslint-disable */
import { _GetUserProfile } from "@Services/Common";
import { createContext, ReactElement, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "types/Auth";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const initialState: AuthContext = {
  userData: null,
  imageLoaded: null,
  setImageLoaded: () => null,
};

export const AuthDatasContext = createContext<AuthContext>(initialState);

type Props = {
  children?: React.PropsWithChildren<any>;
};

export default function AiProvider({ children }: Props): ReactElement {
  const [imageLoaded, setImageLoaded] = useState(false);
  const {
    i18n: { language },
  } = useTranslation();
  const { data: userData } = useQuery("getUserRole", () => _GetUserProfile());

  useEffect(() => {
    if (!Cookies.get("BAIMS_TOKEN")) {
      window.location.href = `${process.env.REACT_APP_BAIMS_URL}login?url_redirect=${window.location.host}&url=${window.location.host}&from=web`;
    }
  }, [Cookies.get("BAIMS_TOKEN")]);

  return (
    <AuthDatasContext.Provider
      value={{
        userData,
        imageLoaded,
        setImageLoaded,
      }}
    >
      {children}
    </AuthDatasContext.Provider>
  );
}
