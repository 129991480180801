import { ReactElement, useContext } from "react";
import { LanguageWrapper, ProfileBtn } from "../Navbar.styled";
import { AuthDatasContext } from "context/Auth.context";
import ContentLoader from "react-content-loader";

export default function ProfileButton(): ReactElement {
  const { userData } = useContext(AuthDatasContext);

  return (
    <LanguageWrapper>
      <ProfileBtn type="button">
        {userData ? (
          <img
            src={
              userData?.portrait_picture?.url
                ? `${userData?.portrait_picture?.url}`
                : `${userData?.profile_picture?.url}`
            }
            alt={userData?.name + "_image"}
          />
        ) : (
          <ContentLoader speed={2} width={"100%"} height="50px" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect x="0" y="0" rx="30" ry="30" width="40" height="40" />
            <rect x="50" y="5" rx="3" ry="3" width="150" height="10" />
            <rect x="50" y="25" rx="3" ry="3" width="120" height="10" />
          </ContentLoader>
        )}
      </ProfileBtn>
    </LanguageWrapper>
  );
}
