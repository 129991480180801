import React, { useState, useRef } from "react";
import ImageCropper from "@Components/ImageCropper";
import { InputBarContainer, StyledForm, StyledLabel, StyledUploadForm, UploadImageWrapper } from "./InputBar.styled";
import { TextField } from "@mui/material";
import UploadImageIcon from "images/icons/UploadImageIcon";
import { PrestoButton } from "@Components/BaimsMessage/BaimsMessage.styled";
import { useTranslation } from "react-i18next";
interface InputBarProps {
  showCropper: any;
  setShowCropper: any;
  setImageToCrop: any;
  hideCropper?: any;
  onSendMessage: (content: string, type: "text" | "image") => void;
  onImageSelect: (imageFile: File) => void;
}

const InputBar: React.FC<InputBarProps> = ({
  showCropper,
  setShowCropper,
  setImageToCrop,
  hideCropper,
  onSendMessage,
  onImageSelect,
}) => {
  const { t } = useTranslation("common");
  const [text, setText] = useState("");
  const [fileData, setFileData] = useState<any>();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleTextSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (text.trim()) {
      onSendMessage(text, "text");
      setText("");
    }
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onImageSelect(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
        setShowCropper(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageCrop = (croppedImage: Blob | null) => {
    if (croppedImage) {
      setCroppedImage(croppedImage);
      setShowCropper(false);
    }
  };

  const handleImageSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (croppedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        onSendMessage(reader.result as string, "image");
        setImagePreview(null);
        setCroppedImage(null);
      };
      reader.readAsDataURL(croppedImage);
    }
  };

  return (
    <InputBarContainer>
      <div style={{ maxWidth: "555px", margin: "auto" }}>
        <StyledForm onSubmit={handleTextSubmit}>
          <TextField
            type="text"
            id="outlined-multiline-flexible"
            value={text}
            onChange={handleTextChange}
            placeholder={t("ask_placeholder")}
            multiline
            fullWidth
            maxRows={6}
          />
        </StyledForm>

        <StyledUploadForm onSubmit={handleImageSubmit}>
          <div>
            <UploadImageWrapper>
              <StyledLabel htmlFor="image-input">
                <UploadImageIcon />
              </StyledLabel>
              <PrestoButton isActive={text.length === 0} onClick={(e) => handleTextSubmit(e)}>
                {t("ask")}
              </PrestoButton>
            </UploadImageWrapper>
            <input
              id="image-input"
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={(e) => {
                const file = e.target.files?.[0];
                setFileData(file);
                if (file) {
                  onImageSelect(file);
                  handleImageSelect(e);
                }
              }}
              className="form-control d-none"
            />
            {/* <div className="image-preview" style={{ backgroundImage: `url(${imagePreview})` }}>
            {imagePreview ? null : 'No image selected'}
          </div> */}
          </div>
          <div>{croppedImage && <button type="submit">Send Image</button>}</div>
        </StyledUploadForm>
        {showCropper && hideCropper && (
          <ImageCropper
            showCropper={showCropper}
            setImageToCrop={setImageToCrop}
            setShowCropper={setShowCropper}
            setCroppedImage={setCroppedImage}
            setImagePreview={setImagePreview}
            src={imagePreview || ""}
            onCrop={handleImageCrop}
          />
        )}
        {croppedImage && (
          <div className="cropped-preview" style={{ backgroundImage: `url(${URL.createObjectURL(croppedImage)})` }}>
            Cropped image preview
          </div>
        )}
      </div>
    </InputBarContainer>
  );
};

export default InputBar;
