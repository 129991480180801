import styled from "styled-components";

export const EmptyStateWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const EmptyStateTitle = styled.h2`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.nero};
  text-align: center;
  margin: 16px 0 8px;
  line-height: 1.75;
  font-weight: 500;
`;
export const EmptyStateDesc = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: ${({ theme }) => theme.colors.gray2};
  text-align: center;
  margin: 16px 0 8px;
`;
