import { useTranslation } from "react-i18next";
import EmptyIcon from "./EmptyIcon";
import { EmptyStateDesc, EmptyStateTitle, EmptyStateWrapper } from "./EmptyState.styled";

export default function EmptyState() {
  const { t } = useTranslation("common");
  return (
    <EmptyStateWrapper>
      <EmptyIcon />
      <EmptyStateTitle>{t("no_massages_yet")}</EmptyStateTitle>
      <EmptyStateDesc>{t("ask_by_sending")}</EmptyStateDesc>
    </EmptyStateWrapper>
  );
}
