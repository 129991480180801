import { SendButton } from "@Components/InputBar/InputBar.styled";
import styled from "styled-components";

export const CroppedContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 698px;
  height: 392px;
  margin: 0 auto;
  z-index: 150;
  .cropper-container,
  .cropper-bg {
    width: 698px !important;
  }
  @media (max-width: 769px) {
    width: 500px;
    height: 280px;
    .cropper-container,
    .cropper-bg {
      width: 500px !important;
      height: 280px;
    }
  }
  @media (max-width: 577px) {
    width: 380px;
    height: 200px;
    .cropper-container,
    .cropper-bg {
      width: 380px !important;
      height: 200px;
    }
  }
  @media (max-width: 376px) {
    width: 300px;
    height: 120px;
    .cropper-container,
    .cropper-bg {
      width: 300px !important;
      height: 120px;
    }
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

export const BgBlur = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 30;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(3px);
  z-index: 120;
`;

export const CroppedImage = styled.img``;

export const CropButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const CropButton = styled(SendButton)`
  margin: 12px 0px;
  width: 81px;
  height: 40px;
  border-radius: 10px;
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.gray8};
  }
`;

export const CancleCropButton = styled(SendButton)`
  margin: 12px 0px;
  width: 81px;
  height: 40px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.seagullNeutrals};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  color: ${({ theme }) => theme.colors.seagule};
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.gray8};
  }
`;
