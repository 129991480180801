import React, { useEffect, useLayoutEffect, useState } from "react";
import { QueryClientProvider } from "react-query";
import QueryClientConfig from "./configs/QueryClientConfig";
import { _GetUserProfile } from "./services/Common";
import theme from "./stylesheets/theme";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { isRTL } from "utils/misc";
import stylisRTLCSS from "stylis-rtlcss";
import { GridThemeProvider } from "styled-bootstrap-grid";
import GridConfig from "@Configs/GridConfig";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import PagesRoute from "routes";
import { GlobalStyle } from "@Style/GlobalStyle";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import * as Mui from "@mui/material/styles";
import { prefixer } from "stylis";
import ErrorBoundary from "@Components/ErrorBoundry";
import "moment/locale/ar";
import "moment/locale/tr";
import moment from "moment";

const cacheLtr = createCache({
  key: "muiltr",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const ltrTheme = Mui.createTheme({
  direction: "ltr",
  typography: {
    fontFamily: ["AvenirNextWorld", "sans-serif"].join(","),
  },
});
const rtlTheme = Mui.createTheme({
  direction: "rtl",
  typography: {
    fontFamily: ["AvenirNextWorld", "sans-serif"].join(","),
  },
});

const App: React.FC = () => {
  const [isRtl, setIsRtl] = useState(false);
  const {
    i18n: { language },
  } = useTranslation("common");

  useEffect(() => {
    if (language === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }

    moment.locale(language);
  }, [language]);

  useLayoutEffect(() => {
    document.body.setAttribute("dir", isRtl ? "rtl" : "ltr");
  }, [isRtl]);

  return (
    <StyleSheetManager stylisPlugins={isRTL(language) ? [stylisRTLCSS] : []}>
      <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
        <Mui.ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
          <ThemeProvider theme={theme}>
            <GridThemeProvider gridTheme={GridConfig}>
              <>
                <GlobalStyle />
                <ErrorBoundary>
                  <QueryClientProvider client={QueryClientConfig}>
                    <Helmet
                      title="Baims AI"
                      htmlAttributes={{
                        dir: isRTL(language) ? "rtl" : "ltr",
                        lang: language,
                      }}
                    />
                    <PagesRoute />
                  </QueryClientProvider>
                </ErrorBoundary>
              </>
            </GridThemeProvider>
          </ThemeProvider>
        </Mui.ThemeProvider>
      </CacheProvider>
    </StyleSheetManager>
  );
};

export default App;
