import { LOCALE_COOKIE_NAME } from "@Configs/constants";
import { configureAxiosLocale } from "@Configs/Axios";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useClickAway, useCookie, useMedia } from "react-use";
import {
  ArrowIcon,
  DropdownLang,
  DropdownWrapper,
  GlobeIcon,
  LanguageWrapper,
  NavbarDropdown,
  SelectedLanguage,
} from "../Navbar.styled";

interface Props {
  setShowBlurredBg: Dispatch<SetStateAction<boolean>>;
  setResMenu?: (setResMenu: boolean) => void;
}

export default function NavbarLangSwitch({ setShowBlurredBg, setResMenu }: Props) {
  const { t, i18n } = useTranslation("common");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [locale, updateCookieLang] = useCookie(LOCALE_COOKIE_NAME);
  const dropdownRef = useRef(null);
  const isDesktop = useMedia("(min-width: 992px)");
  const isMobile = useMedia("(max-width: 575.98px)");
  const [locales, setLocales] = useState(["en", "ar", "tr"]);

  const switchLang = (lang) => {
    configureAxiosLocale(lang);
    updateCookieLang(lang);
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  useClickAway(dropdownRef, () => {
    setIsDropdownOpen(false);
    if (window && window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(true);
    }
  });
  useEffect(() => {
    setIsDropdownOpen(false);
  }, [locale]);

  useEffect(() => {
    setShowBlurredBg(isDropdownOpen);
    if (window && window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(!isDropdownOpen);
    }
  }, [isDropdownOpen]);

  return (
    <LanguageWrapper ref={dropdownRef}>
      <DropdownWrapper type="button" isOpen={isDropdownOpen} onClick={() => setIsDropdownOpen((prev) => !prev)}>
        <GlobeIcon src={`/images/icons/globe-black.svg`} alt="Language" />
        {isDesktop && <SelectedLanguage>{locale.toUpperCase()}</SelectedLanguage>}
        {isMobile && <SelectedLanguage>{t(locale as any)}</SelectedLanguage>}
        <ArrowIcon src={`/images/icons/navbar-dropdown-icon-black.svg`} alt="Language" aria-hidden="true" />
      </DropdownWrapper>
      <NavbarDropdown isOpen={isDropdownOpen}>
        {locales.map((lng) => (
          <li key={"Switch-Lang-" + lng}>
            <DropdownLang
              isSelected={locale === lng}
              className={lng === "ar" ? "ar" : ""}
              onClick={(e) =>
                lng !== locale ? switchLang(lng) : [e.preventDefault(), setIsDropdownOpen((prev) => !prev)]
              }
              href={"/"}
              locale={lng !== locale ? lng : locale}
            >
              {t(lng as any)}
            </DropdownLang>
          </li>
        ))}
      </NavbarDropdown>
    </LanguageWrapper>
  );
}
