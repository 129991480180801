import React, { useState, useRef, useContext, Fragment } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import {
  BgBlur,
  CancleCropButton,
  CropButton,
  CropButtonsWrapper,
  CroppedContainer,
  CroppedImage,
  SpinnerContainer,
} from "./ImageCropper.styled";
import { AuthDatasContext } from "context/Auth.context";
import CircularProgress from "@mui/material/CircularProgress";
import theme from "@Style/theme";
import { useTranslation } from "react-i18next";

interface ImageCropperProps {
  src: string;
  isLoading?: boolean;
  showCropper: any;
  setShowCropper: any;
  setImageToCrop: any;
  setCroppedImage?: any;
  setImagePreview?: any;
  onCrop: (croppedImage: Blob | null) => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  src,
  isLoading,
  showCropper,
  setShowCropper,
  setImageToCrop,
  setCroppedImage,
  setImagePreview,
  onCrop,
}) => {
  const { imageLoaded, setImageLoaded } = useContext(AuthDatasContext);
  const imageRef = useRef<HTMLImageElement>(null);
  const cropperRef = useRef<Cropper>();
  const { t } = useTranslation("common");

  const handleImageLoad = () => {
    setImageLoaded(true);
    if (imageRef.current) {
      cropperRef.current = new Cropper(imageRef.current, {});
    }
  };

  const handleImageCancel = () => {
    setShowCropper(false);
    setImageToCrop(null);
    setImageLoaded(false);
    cropperRef.current.clear();
    cropperRef.current.destroy();
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      cropperRef.current.getCroppedCanvas().toBlob((blob) => {
        onCrop(blob);
      });
    }
  };

  return (
    <CroppedContainer>
      <CroppedImage
        ref={imageRef}
        src={src}
        alt="To be cropped"
        onLoad={handleImageLoad}
        style={{ display: imageLoaded ? "block" : "none" }}
      />
      {imageLoaded && (
        <CropButtonsWrapper>
          <CancleCropButton disabled={isLoading} type="button" onClick={() => handleImageCancel()}>
            {t("cancel")}
          </CancleCropButton>
          <CropButton type="button" disabled={isLoading} onClick={handleCrop}>
            {isLoading ? (
              <SpinnerContainer
                style={{
                  color: theme.colors.gray1,
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </SpinnerContainer>
            ) : (
              <span>{t("ask")}</span>
            )}
          </CropButton>
        </CropButtonsWrapper>
      )}
    </CroppedContainer>
  );
};

export default ImageCropper;
