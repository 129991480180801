// Packages
import { ReactNode } from "react";
import { UrlObject } from "url";
import { Link as ReactLink } from "react-router-dom";

type Props = {
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  onMouseEnter?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  children: ReactNode;
  className?: string;
  href?: string | UrlObject | null;
  locale?: string;
};

const Link = ({ onClick, onMouseEnter, children, className, href, locale, ...props }: Props) =>
  href ? (
    <ReactLink {...props} to={href}>
      <a {...props} onClick={onClick} className={className} onMouseEnter={onMouseEnter}>
        {children}
      </a>
    </ReactLink>
  ) : (
    <a {...props} onClick={onClick} className={className}>
      {children}
    </a>
  );

export default Link;
