import styled, { css } from "styled-components";

export const InputBarContainer = styled.div`
  padding: 16px 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray5_old}; ;
`;

export const StyledForm = styled.form`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  .MuiFormControl-root,
  .MuiTextField-root {
    display: flex;
    width: 100%;
  }
  .MuiInputBase-root {
    padding: 6px 3px 6px 12px;
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    font-size: ${({ theme }) => theme.fontSizes.body16};
    line-height: 28px;
    color: ${({ theme }) => theme.colors.nero};
    background: ${({ theme }) => theme.colors.gray7};
    border-radius: 10px;
  }
  .MuiInputBase-root:before {
    border: none;
    outline: none;
  }
  .MuiInputBase-root:hover:not(.Mui-disabled):before {
    border: none;
    outline: none;
  }
  .MuiSelect-select:focus {
    border-radius: 10px !important;
  }
  .Mui-disabled:before {
    border: none;
    outline: none;
    border-style: none !important;
  }
  .Mui-disabled {
    opacity: 0.5;
  }
  .MuiInputLabel-root,
  .MuiTypography-root {
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    font-size: ${({ theme }) => theme.fontSizes.body14};
    line-height: 33px;
    color: ${({ theme }) => theme.colors.gray2} !important;
  }
  .MuiInputBase-root:after {
    border: none;
    outline: none;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid #efefef;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #efefef;
  }
  .MuiSelect-icon {
    font-size: 20px;
  }
  .MuiInputBase-input,
  .MuiOutlinedInput-input,
  .MuiInputBase-inputMultiline {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border-radius: 2px;
    }
  }
`;

export const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 24px;
  background: ${({ theme }) => theme.colors.seagule};
  border-radius: 6px;
  padding: 0px 8px;
  border: none;
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledUploadForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 0;
  cursor: pointer;
`;

export const UploadImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
  width: 100%;
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.seagule};
  cursor: pointer;
`;

export const UploadImageText = styled.span`
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.seagule};
`;

export const CheckMarkWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 6px;
  z-index: 999;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
  position: relative;
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid ${({ theme }) => theme.colors.gray3};
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input[type="checkbox"]:after {
    display: block;
    content: url("../images/icons/check-mark.svg");
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  input[type="checkbox"]:checked {
    background-color: ${({ theme }) => theme.colors.seagule};
    border: none;
  }
  label {
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    font-size: ${({ theme }) => theme.fontSizes.body16};
    line-height: 28px;
    color: ${({ theme }) => theme.colors.nero};
    cursor: pointer;
  }
`;
