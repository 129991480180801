import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL, CLIENT_SECRET, LOCALE_COOKIE_NAME } from "./constants";

const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    common: {
      "client-secret": CLIENT_SECRET,
      "Accept-Language": Cookies.get(LOCALE_COOKIE_NAME),
      token: Cookies.get("BAIMS_TOKEN"),
    },
  },
});

export const configureAxiosLocale = (locale: string) => {
  Axios.defaults.headers.common["Accept-Language"] = locale;
};

export const configureAxiosAuth = (token?: string) => {
  Axios.defaults.headers.common["Authorization"] = token
    ? "Bearer " + token
    : "";
};

export default Axios;
