import Layout from "@Components/Layout";
import NotFound from "@Components/NotFound";
import { Routes, Route } from "react-router-dom";
import ChatBox from "@Components/ChatBox";
import AiProvider from "context/Auth.context";

export default function PagesRoute() {
  return (
    <AiProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<ChatBox />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </AiProvider>
  );
}
